import styled from "styled-components";
import { Link } from 'gatsby';

export const StyledArticleContainer = styled.main<any>`
  max-width: 1010px;  
  padding: ${props => props.theme.layout.gutter}px;
  font-family: ${props => props.theme.type.fontFamily};
  font-size: ${props => props.theme.type.nav.fontSize}px;
  color: ${props => props.theme.colors.text};
  
  h2 {
    font-size: inherit;
  }

  a {
    text-decoration: none;
    :visited {
      color: ${props => props.theme.colors.text};
    }
    transition: all 0.15s ease-in-out;
    // prevent "sticky hover" when tapping on iphone
    @media (hover: hover) {
      &:hover {
        color: ${props => props.theme.colors.hover};
      }
    }
  }
`;

export const StyledDate = styled.div<any>`
  margin-top: 16px;
  margin-bottom: ${props => props.theme.layout.gutter}px;
`;

export const NextPrevContainer = styled.div<any>`
  height: 40px;
  padding: 6px 0 60px 0;
`;

export const NextPrevLink = styled(Link)`
  color: ${props => props.theme.colors.text};
  margin-right: ${props => props.theme.layout.gutter}px;
`;

export const ArticleContent = styled.section<any>`
  margin: 0;
  padding: 0;
  p {
    margin-top: ${({ theme }) => theme.type.nav.lineHeight}px;
    margin-bottom: ${({ theme }) => theme.type.nav.lineHeight}px;
  }
  img {
    width: 100%;
  }
  iframe {
    max-width: 200%;
  }
`;

export const IframeWrapper = styled.div<any>`
  position: relative;
  overflow: hidden;
  // padding-top keeps video in 9 x 16 ratio, which is YT default
  padding-top: 56.25%;
`;

export const Stylediframe = styled.iframe<any>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

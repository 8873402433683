import React from "react";
import ReactMarkdown from "react-markdown";
import {
  StyledArticleContainer,
  NextPrevContainer,
  NextPrevLink,
  StyledDate,
  ArticleContent,
  IframeWrapper,
  Stylediframe,
} from "../assets/PageStyles/newsArticleStyle";

const renderers = {
  html: ({ value }) => {
    if (!value.includes("iframe")) {
      return null;
    }
    const splitIframe = value.split(" ");
    const iFrameSrcAttribute = splitIframe.find(attribute =>
      attribute.includes("src")
    );
    const iFrameSrc = iFrameSrcAttribute.split('"')[1];

    return (
      <IframeWrapper>
        <Stylediframe
          src={iFrameSrc}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></Stylediframe>
      </IframeWrapper>
    );
  },
};

const NewsArticle = ({ pageContext }) => {
  const {
    newsArticleData: {
      content,
      publishDate,
      nextArticleSlug,
      previousArticleSlug,
      title,
    },
  } = pageContext;

  return (
    <StyledArticleContainer>
      <NextPrevContainer>
        {previousArticleSlug && (
          <NextPrevLink to={`/news/${previousArticleSlug}`}>Back</NextPrevLink>
        )}
        {nextArticleSlug && (
          <NextPrevLink to={`/news/${nextArticleSlug}`}>Next</NextPrevLink>
        )}
      </NextPrevContainer>
      <h2>{title}</h2>
      <StyledDate>{publishDate}</StyledDate>
      <ArticleContent>
        <ReactMarkdown
          allowDangerousHtml
          children={content.content}
          renderers={renderers}
        />
      </ArticleContent>
    </StyledArticleContainer>
  );
};

export default NewsArticle;
